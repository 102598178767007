<template>
  <div class="box">
    <div class="head">
      <!-- 签到状态和签到时间 -->
      <div class="state_time">
        <div class="state">
          <div class="Sign_in_state">签到状态：</div>
          <el-button
            :class="categoryActive == item.id ? 'active' : ''"
            @click="clickButton(item)"
            v-for="item in signinState"
            :key="item.id"
          >
            {{ item.value }}
          </el-button>
        </div>
        <div class="time">
          <div class="time_text">签到时间：</div>
          <div class="time_Box">
            <el-date-picker
              v-model="timeVal"
              type="datetime"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="selectTime"
            >
            </el-date-picker>
          </div>
        </div>
      </div>

      <!-- 导入和搜索 -->
      <div class="import_search">
        <div class="import">
          <el-button class="deep_button" @click="clickImport">
            <div class="flex_align_center">
              <img class="image_import" src="../../../static/images/导出 (1) 2.png" alt="" />
              导入名单
            </div>
          </el-button>
        </div>
        <div class="search">
          <el-input
            class="input"
            clearable
            v-model="searchInput"
            placeholder="请输入用户昵称，手机号查询"
          ></el-input>
          <el-button class="deep_button search_Button" @click="clickSearch">搜索</el-button>
        </div>
      </div>
    </div>

    <div class="context">
      <div class="table">
        <el-table
          :data="tableData"
          ref="multipleTable"
          tooltip-effect="dark"
          :row-key="(row) => row.id"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
        >
          <el-table-column type="selection" width="55" align="center"> </el-table-column>

          <el-table-column label="签到状态" align="center" prop="linkStatus">
            <template slot-scope="scope">
              <div v-if="scope.row.singIn == 0" class="not">
                <div class="back_not"></div>
                <div>未签到</div>
              </div>
              <div v-else class="already">
                <span class="back_already"></span>
                <div>已签到</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="用户ID" align="center" prop="userName">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>

          <el-table-column label="头像" align="center" prop="headPortrait">
            <template slot-scope="scope">
              <img
                style="
                   {
                    width: 48px;
                    height: 48px;
                    border-radius: 4px;
                  }
                "
                :src="scope.row.headPortrait"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center" prop="userName">
            <template slot-scope="scope">{{ scope.row.userName }}</template>
          </el-table-column>
          <el-table-column label="手机号" align="center" prop="mobile">
            <template slot-scope="scope">{{ scope.row.mobile }}</template>
          </el-table-column>
          <el-table-column label="微信号" align="center" prop="weChatId">
            <template slot-scope="scope">{{ scope.row.weChatId }}</template>
          </el-table-column>

          <el-table-column label="机构" align="center" prop="weChatId">
            <template slot-scope="scope">{{ scope.row.institution }}</template>
          </el-table-column>

          <el-table-column label="职位" align="center" prop="weChatId">
            <template slot-scope="scope">{{ scope.row.position }}</template>
          </el-table-column>

          <el-table-column label="签到时间" align="center" prop="weChatId">
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
          </el-table-column>

          <el-table-column label="用户等级" align="center" prop="whetherMember">
            <template slot-scope="scope">
              <div v-if="scope.row.whetherMember === 0">普通用户</div>
              <div v-if="scope.row.whetherMember === 1">会员用户</div>
              <div v-if="scope.row.whetherMember === 5">超级会员</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="180" prop="linkStatus">
            <template slot-scope="scope">
              <div class="to_distribution_list">
                <div @click="ToDistributionList(scope.row)" class="see">查看详情</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { selectSignIn } from '../../api/distribution'
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      // 签到状态
      signinState: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '已签到',
          id: 0
        },
        {
          value: '未签到',
          id: 1
        }
      ],
      // 签到按钮的选中状态
      categoryActive: 999,
      // 签到时间
      timeVal: '',
      // 搜索框的值
      searchInput: '',
      // 表格数据
      tableData: [],
      // 发送请求数据
      query: {
        actibityId: null,
        current: 1,
        size: 10,
        criteria: '' // 搜索条件
      },
      // 总数据条数
      total: 0
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  methods: {
    // 签到状态的按钮点击事件
    clickButton(item) {
      if (item.id == 999) {
        this.categoryActive = item.id
        // this.querySearch.distributionType = null
        // this.search()
      } else {
        this.categoryActive = item.id
        // this.querySearch.distributionType = this.categoryActive
        // this.search()
      }
    },
    // 签到时间
    selectTime() {
      // console.log(this.timeVal)
    },
    // 点击搜索
    clickSearch() {
      this.query.criteria = this.searchInput
      this.search()
      // console.log(this.searchInput)
    },
    // 导出名单
    clickImport() {
      //  console.log('导出名单')
      return this.$message('敬请期待!')
    },
    // 表格中按钮选择的数据
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 操作->查看详细->要跳转
    ToDistributionList(row) {
      // console.log(row)
      // console.log('查看详情')
      this.$router.push({
        path: '/userManagement/userDetail?id=' + row.id
      })
    },
    async search() {
      const { data: res } = await selectSignIn(this.query)
      // console.log(res)
      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
      }
    }
  },

  created() {
    this.query.actibityId = this.$route.query.id
    this.search()
  },
  mounted() {}
}
</script>
<style scoped lang="less">
.box {
  padding: 16px 16px 0 24px;
  .head {
    width: 100%;
    height: 129px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 12px;
    padding: 0 51px 0 10px;
    margin-bottom: 16px;
    .state_time {
      width: 100%;
      height: 65px;
      border-bottom: 1px dashed #ededed;
      display: flex;

      .state {
        display: flex;
        align-items: center;
        margin-right: 50px;
        .Sign_in_state {
          font-size: 16px;
          color: #333;
        }
      }
      .time {
        display: flex;
        align-items: center;

        .time_text {
          font-size: 16px;
          color: #333;
        }
      }
    }

    .import_search {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search {
        display: flex;

        .input {
          /deep/.el-input__inner {
            width: 382px;
          }
        }
        .search_Button {
          margin-left: 25px;
        }
      }

      .import {
        .flex_align_center {
          display: flex;
          align-items: center;

          .image_import {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
        /deep/.el-button {
          padding: 10px 15px;
        }
      }
    }
  }

  .context {
    background-color: #fff;
    border-radius: 12px;
    // width: 100%;
    padding: 16px 20px 0 15px;
    .table {
      .not {
        color: #ff7043;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .back_not {
          width: 8px;
          height: 8px;
          background-color: #ff7043;
          border-radius: 50%;
        }
      }
      .to_distribution_list {
        color: #7c71e1;
        .see {
          cursor: pointer;
        }
      }

      .already {
        color: #7c71e1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .back_already {
          width: 8px;
          height: 8px;
          background-color: #7c71e1;
          border-radius: 50%;
        }
      }
    }
  }
}
.active {
  color: #fff;
  background-color: #8598ff;
}
.deep_button {
  color: #fff;
  background-color: #7c71e1;
  border-radius: 4px;
}
</style>